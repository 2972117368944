section {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  width: 40rem;
  background: #FFF;
  margin-top: 3rem;
  padding: 1rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;

  .imgContainer {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: var(--light-blue);
    position: relative;
    margin-bottom: 2.5rem;
    
    .imgContent {
      z-index: 10;

      .quoteIcon {
        border-radius: 50%;
        padding: .7rem;
        background-color: var(--light-blue);
        position: absolute;
        z-index: 20;
        top: 10%;
        left: -7%;
        height: 2.5rem;
        width: 2.5rem;
        
        svg {
          color: #FFF;
        }
      }

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        bottom: -0.3rem;
        left: -0.5rem;
      }
    }
  }

  .heading {
    padding-bottom: 1rem;

    h2 {
      color: var(--dark-blue);
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.07rem;
      text-transform: capitalize;
      padding-bottom: 0.05rem;
    }

    h6 {
      font-weight: 400;
      font-size: 0.80rem;
      text-transform: uppercase;
      color: var(--light-blue);
    }
  }

  .content {
    font-size: 1rem;
    color: var(--text);
    line-height: 1.6rem;
    padding-bottom: 1rem;
  }

  .arrows {
    button {
      font-size: 3rem;
      background: none;
      border: none;
      cursor: pointer;
      opacity: .6;

      &:hover {
        opacity: 1;
        transition: opacity 2s;
      }
    }
  }

    
  .surpriseBtn {
    height: 2rem;
    width: 6rem;
    color: var(--light-blue);
    background-color: var(--light-gray);
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    padding-bottom: 0.15rem;

    &:hover {
      color: var(--light-gray);
      background-color: var(--light-blue);
      transition: color, background-color 1s;
    }
  }
}