.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--dark-blue);
  }
  
  span {
    height: 0.25rem;
    background-color: var(--light-blue);
    display: inline-block;
    width: 5rem;
    margin-top: .5rem;
  }
}